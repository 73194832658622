<template>
	<div class="p-grid p-mt-4" v-if="user">
    <div class="p-col-12 p-md-12 p-lg-6 p-fluid ">
      <Panel header="Общие данные">
        <table>
          <tbody style="text-align: left">
            <tr>
              <th class="p-p-1">Status:</th>
              <td class="p-p-1">
                <span class="p-ml-3">{{ user.employee?.status }}</span>
              </td>
            </tr>
            <tr>
              <th class="p-p-1">Name:</th>
              <td class="p-p-1">
                <span class="p-ml-3">
                  <span style="margin-right: 2px">{{ user.first_name }} {{ user.last_name }}</span>
                  <span style="top: -1px; transform: scale(0.45)" :class="'flag--datatable flag flag-' + user.flag_code"></span>
                </span>
              </td>
            </tr>
            <tr>
              <th class="p-p-1">{{ $t('Personal code') }}:</th>
              <td class="p-p-1">
                <span class="p-ml-3">{{ user.employee?.personal_code }}</span>
              </td>
            </tr>
            <tr>
              <th class="p-p-1">{{ $t('Role') }}:</th>
              <td class="p-p-1">
                <span class="p-ml-3" v-if="user.role">{{ computedRole(+user.role) }}</span>
              </td>
            </tr>
            <tr>
              <th class="p-p-1">{{ $t('Position') }}:</th>
              <td class="p-p-1">
                <span class="p-ml-3">{{ user.employee?.position }}</span>
              </td>
            </tr>
            <tr>
              <th class="p-p-1">{{ $t('Birthdate') }}:</th>
              <td class="p-p-1">
                <span class="p-ml-3" v-if="user.birthdate">{{ formatBirthDate(user.birthdate) }}</span>
              </td>
            </tr>
            <tr>
              <th class="p-p-1">{{ $t('Phone') }}:</th>
              <td class="p-p-1">
                <span class="p-ml-3"><span v-if="user.phone_code">+</span>{{ user.phone_code || '' }} {{ user.phone_number || '' }}</span>
              </td>
            </tr>
            <tr>
              <th class="p-p-1">Work phone:</th>
              <td class="p-p-1">
                <span class="p-ml-3" v-if="user.employee"><span v-if="user.employee.work_phone_code">+</span>{{ user.employee.work_phone_code || '' }} {{ user.employee.work_phone_number || '' }}</span>
              </td>
            </tr>
            <tr>
              <th class="p-p-1">{{ $t('Email') }}:</th>
              <td class="p-p-1">
                <span class="p-ml-3" v-if="user.type === constants.userTypes.legal_entity && user.company_name">{{ user.company_email || '' }} </span>
                <span class="p-ml-3" v-else>{{ user.email || '' }}</span>
              </td>
            </tr>
            <tr>
              <th class="p-p-1">{{ $t('Address') }}:</th>
              <td class="p-p-1">
                <div v-if="user.address" class="p-ml-3">
                  <span class="p-mr-1" v-show="user.address.address">{{ user.address.address }},</span>
                  <span class="p-mr-1" v-show="user.address.address2">{{ user.address.address2 }},</span>
                  <span class="p-mr-1" v-show="user.address.city">{{ user.address.city }},</span>
                  <span class="p-mr-1" v-show="user.address.state">{{ user.address.state }},</span>
                  <span class="p-mr-1" v-show="user.address.postcode">{{ user.address.postcode }},</span>
                  <span class="p-mr-1" v-if="user.address.country">{{ user.address.country.name }} </span>
                </div>
              </td>
            </tr>
            <tr>
              <th class="p-p-1">{{ $t('Contract') }}:</th>
              <td class="p-p-1">
                <span class="p-ml-3">{{ user.employee?.contract }} </span>
              </td>
            </tr>
            <tr>
              <th class="p-p-1">{{ $t('Start') }}:</th>
              <td class="p-p-1">
                <span class="p-ml-3">{{ formatDay(user.employee?.start) }} </span>
              </td>
            </tr>
            <tr>
              <th class="p-p-1">{{ $t('End') }}:</th>
              <td class="p-p-1">
                <span class="p-ml-3">{{ formatDay(user.employee?.end) }} </span>
              </td>
            </tr>
            <tr>
              <th class="p-p-1">{{ $t('Comment') }}:</th>
              <td class="p-p-1">
                <span class="p-ml-3">{{ user.comment }}</span>
              </td>
            </tr>
            <tr>
              <th class="p-p-1">Last login:</th>
              <td class="p-p-1">
                <span class="p-ml-3" v-if="user.last_login">{{ formatDate(user.last_login) }}</span>
              </td>
            </tr>
            <tr>
              <th class="p-p-1">{{ $t('Created') }}:</th>
              <td class="p-p-1">
                <span class="p-ml-3" v-if="user.created_at">{{ formatDate(user.created_at) }}<span v-if="user.creator">, {{ user.creator.first_name }} {{ user.creator.last_name }}</span></span>
              </td>
            </tr>
          </tbody>
        </table>
      </Panel>
    </div>

<!--    <div class="p-col-12 p-md-12 p-lg-6 p-fluid ">-->
<!--      <Panel header="Другая информация">-->
<!--        <table>-->
<!--          <tbody style="text-align: left">-->
<!--&lt;!&ndash;            <tr v-if="$store.state.branchData.use_client_card === 1">&ndash;&gt;-->
<!--            <tr>-->
<!--              <th class="p-p-1">Balance:</th>-->
<!--              <td class="p-p-1">-->
<!--                <span class="p-ml-3">{{ formatCurrency(+user.balance || 0) }}</span>-->
<!--              </td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <th class="p-p-1">Credit limit:</th>-->
<!--              <td class="p-p-1">-->
<!--                <span class="p-ml-3" v-if="user.type === constants.userTypes.legal_entity && user.company_name">{{ formatCurrency(+user.credit_limit || 0) }}</span>-->
<!--                <span class="p-ml-3" v-else>-</span>-->
<!--              </td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <th class="p-p-1">Days to pay:</th>-->
<!--              <td class="p-p-1">-->
<!--                <span class="p-ml-3">{{ user.days_to_pay || 0 }}</span>-->
<!--              </td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <th class="p-p-1">Services discount:</th>-->
<!--              <td class="p-p-1">-->
<!--                <span class="p-ml-3">{{ user.services_discount || 0 }}%</span>-->
<!--              </td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <th class="p-p-1">Products discount:</th>-->
<!--              <td class="p-p-1">-->
<!--                <span class="p-ml-3">{{ user.products_discount || 0 }}%</span>-->
<!--              </td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <th class="p-p-1">Default tax:</th>-->
<!--              <td class="p-p-1">-->
<!--                <span class="p-ml-3" v-if="user.type === constants.userTypes.legal_entity && user.company_name && user.tax">{{ user.tax.value || 0 }}%</span>-->
<!--                <span class="p-ml-3" v-else>-</span>-->
<!--              </td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <th class="p-p-1">{{ $t('Client card') }}:</th>-->
<!--              <td class="p-p-1">-->
<!--                <span class="p-ml-3">{{ formatDate(user.client_card) || '-' }}</span>-->
<!--              </td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <th class="p-p-1">{{ $t('Birthdate') }}:</th>-->
<!--              <td class="p-p-1">-->
<!--                <span class="p-ml-3" v-if="user.type === constants.userTypes.legal_entity && user.company_name">-</span>-->
<!--                <span class="p-ml-3" v-else-if="user.birthdate">{{ formatDate(user.birthdate) }}</span>-->
<!--              </td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <th class="p-p-1">{{ $t('Comment') }}:</th>-->
<!--              <td class="p-p-1">-->
<!--                <span class="p-ml-3">{{ user.comment }}</span>-->
<!--              </td>-->
<!--            </tr>-->
<!--          </tbody>-->
<!--        </table>-->
<!--      </Panel>-->
<!--    </div>-->
  </div>


</template>

<script>
// import httpClient from '@/services/http.services'
import settings from '@/settings.js'
import constants from '@/constants'
import roles from '@/translations/roles'
import formatMixins from "@/mixins/formatMixins";

export default {
  mixins: [ formatMixins ],
  // components: {  },
  props: {
    user: Object,
  },
	data() {
		return {
      userId: null,
      activeIndex: '',
      isLoading: true,
      userData: null,
      settings,
      constants
		}
	},
  methods: {
    computedRole(roleNum) {
      // return roles.filter(role => role.num === roleNum)[0].name
      return roles.find(role => role.num === roleNum)?.name[this.$i18n.locale]
    },
	},
  computed: {
    itemToDeleteData() {
      return 'delete data'
    },
  }
}
</script>

<style scoped lang="scss">
.datatable-wrapper {
  overflow-y: auto;
  padding-bottom: 25px;
}

.positions-table {
  border-collapse: collapse;
  width: 100%;
  tr {
    td {
      padding: 8px !important;
    }
  }
}
</style>